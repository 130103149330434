export const initQuickOrderForm = function () {
  $('.quick-order-form').each(function (i, form) {
    const messageEl = $(form).find('#' + form.id + '_error');
    messageEl.hide();

    $(form).on('submit', function (e) {
      e.preventDefault();
      const url = $(form).attr('action');
      const type = $(form).attr('method');
      const data = $(form).serialize();
      const button = $(form).find('input[type="submit"]');
      button.attr('disabled', true);
      $.ajax({url, type, data, success: function (response) {
        const message = $(response).find('#' + form.id + '_error');
        messageEl.html(message.html()).show();
        $(form).find('fieldset').hide();
        $(form).find('.btn-toolbar').hide();
        button.attr('disabled', false);
      }});
    })
  })
};