export const initSmoothScrolling = function() {

    $("a").on("click", function(event) {
        var link = this.href.split("#")[0];

        var href = window.location.href.split("#")[0];

        // console.log(link,href);
        if (this.hash !== "" && href == link ) {
            event.preventDefault();
            var hash = this.hash;
            scrollToAnchor(hash);
        }
    });
}


function scrollToAnchor(hash) {

    if (hash == "#top") {
        var endPoint = 0;
    } else {
        var target = $("a[name=" + hash.substring(1) + "]");
        if (target.offset() == undefined) {
            target = $(hash);
        }

        var offset = $(".site-header").height(); //130;


        var endPoint = target.offset().top - offset;
        if (endPoint < 222) {
            endPoint = 0;
        }
    }
    $("html, body").animate({
        scrollTop: endPoint
    }, 600, function(){
        // Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = hash;
    });
}