import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
window.jquery = jquery;

import { Slider, Sticky, Reveal, Accordion } from 'foundation-sites';
import { initForms } from './modules/forms';
import { initNavigation } from './modules/navigation';
// import { initFontAwesome } from './modules/fontAwesome';
import { initFilterForm } from './modules/filterform';
import { initSideCart } from './modules/sideCart';
import { initCart } from './modules/cart';
import { initProductGallerySwiper, initProductSwiper, initVivaBanners } from './modules/sliders';
import { initExpandableContent } from './modules/expandableContent';
import { initProductVideos } from './modules/productVideos';
import { initLazyLoad } from './modules/lazyLoad';
import { initCheckout } from './modules/checkout';
import { initMasonry } from './modules/masonry';
import{ initGallery } from './modules/gallery'
import{ initColumns } from './modules/columns'
import{ initSmoothScrolling } from './modules/smoothScroll'
import{ initPostCodeCheck } from './modules/postCodeCheck'
import{ initQuickOrderForm } from './modules/quickOrderForm'
import CookieConsent from './modules/cookieconsent';
import { initCaravanRentals } from './modules/caravanRentals';
import { initProductDetail } from './modules/productDetail';
import {initSearch} from "./modules/search";
import {initMultiVideoBlock} from "./modules/multiVideoBlock";

// import './whatsapp/venomButton';

{
  'use strict';

  // Push consent to DataLayer
  const consent = new CookieConsent();
  consent.pushToDataLayer();

  // If Foundation modules are installed uncomment this line
  $(document).ready(() => {
    
    $(document).foundation();
    // document.body.innerHTML = document.body.innerHTML.replace(/\?stage=Stage/g, "?");

    initForms();
    initSearch();
    initFilterForm();
    initNavigation();
    // initFontAwesome();
    initVivaBanners();
    initSideCart();
    initCart();
    initPostCodeCheck();
    initProductGallerySwiper();
    initProductSwiper();
    initExpandableContent();
    initProductVideos();
    initLazyLoad();
    initCheckout();
    initMasonry();
    initGallery();
    initColumns();
    initSmoothScrolling();
    initQuickOrderForm();
    initProductDetail();
    initMultiVideoBlock();

    initCaravanRentals();

  //$('#whats-app-button').venomButton({
  //  phone: '+3197025329538',
  //  popupMessage: 'Heeft u een vraag?',
  //  message: "",
  //  showPopup: true,
  //  position: "right",
  //  linkButton: false,
  //  showOnIE: false,
  //  headerTitle: 'Welkom bij Linberg!',
  //  headerColor: '#25d366',
  //  backgroundColor: '#25d366',
  //  buttonImage: '<img src="/app/images/whatsapp.svg" />'
  //});

    const params = new URLSearchParams(window.location.search);
    const ordersent = params.get("ordersent");
    if( ordersent ){
      $('#caravan-offer-form-modal-'+ordersent).foundation('open');
    }

    const ovissent = params.get("ovissent");
    if( ovissent ){
      $('#ovis-offer-form-modal-'+ovissent).foundation('open');
    }

    // Open the newsletter modal if it exists
    const modal = $('#newsletter-modal');
    if (modal.length) {
      new Foundation.Reveal(modal).open();
    }

    $('.content-block__content table').each(function(e){
      $(this).wrap( "<div class='table-scroll'></div>" );
    });

    $('.btn-close-modal').on('click', function () {
      var div = $(this).closest('div.reveal-modal').first();
      $(div).foundation('reveal', 'close');
    })

  });



}
