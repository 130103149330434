import Litepicker from 'litepicker';

export const initCaravanRentals = () => {
    const el = document.getElementById('booking-calendar');
    if (!el) {
        return;
    }

    $("#Form_FilterForm_action_filterRentables").hide();

    // $("#ReservationForm_ReservationForm .btn-toolbar").hide();
    $("#DatePreview").hide();

    const w = $(window).innerWidth();
    if( w<=768 ){
        var columns = 2;
        var months = 2;
    } else if(w>768 && w<=1280) {
        var columns = 2;
        var months = 2;
    } else {
        var columns = 2;
        var months = 2;
    }

    var minDate = window.booking_mindate;
    var maxDate = window.booking_maxdate;

    var startDateField = $('input[name="StartDate"]');
    var endDateField = $('input[name="EndDate"]');

    var startDateVal = startDateField.val() ? new Date(startDateField.val()) : null;
    var endDateVal = endDateField.val() ? new Date(endDateField.val()) : null;

    var optionDates = window.dates_option;

    var picker = new Litepicker({
        element: document.getElementById('booking-calendar'),
        inlineMode: true,
        bookedDays: window.dates_booked,
        disallowBookedDaysInRange: true,
        numberOfColumns: columns,
        numberOfMonths: months,
        startDate: startDateVal,
        endDate: endDateVal,
        format: 'D MMM, YYYY',
        moveByOneMonth: true,
        // disableWeekends: true,
        // highlightedDays: optionDates,
        highlightedDays: window.weekends,
        minDate: minDate,
        maxDate: maxDate,
        minDays: 7,
        selectForward: true,
        scrollToDate: false,
        singleMode: false,
        allowRepick: true,
        useResetBtn: true,
        lang: 'nl-NL',
        /*buttonText: {
            reset: '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16 fa-2x"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z" class=""></path></svg>'
        },*/
        tooltipText: {
            one: 'dag',
            other: 'dagen',
        },
        resetBtnCallback: function(){
            picker.clearSelection();
            $("#DatePreview").hide();
            $("input[name='StartDate']").val('');
            $("input[name='EndDate']").val('');
            const rentableID = $("input[name='RentableID']").val();
            if( rentableID==undefined ) {
                updateFilteredItems();
            } else {
                updateBookingPrice();
            }
        },
        //onRender: function(){
        //    setTimeout( updateWeekends, 10 );
        //},
        //onDayHover: function(){
        //    setTimeout( updateWeekends, 10 );
        //},
        onSelect: function(date1, date2) {
            onSelectDate(date1,date2);
        }
    });

    if( startDateVal ) {
        picker.gotoDate(startDateVal);
    } else {
        picker.gotoDate(minDate);
    }


    if( startDateVal && endDateVal ){
        onSelectDate(startDateVal,endDateVal);
    }

    $("#ReservationForm_ReservationForm_Options input.checkbox").on('change',function(){
        updateBookingPrice();
    });


    $(document).on('click', '.day-item', function(e){

        var lastClicked = $(this);
        setTimeout( function(){
//            console.log(lastClicked);

            //updateWeekends();

            // first visible day, remember to fix autoscroll issue
            var firstDate = $('.day-item').first().data('time');

            var startItem = $('.day-item.is-start-date');
            var endItem = $('.day-item.is-end-date');
            //if( startItem.hasClass('is-weekend') || endItem.hasClass('is-weekend') ){
            if( startItem.hasClass('is-highlighted') || endItem.hasClass('is-highlighted') ){
                picker.clearSelection();
                return;
            }

            var time = startItem.data('time');

            var d = new Date(time);
            var mm = pad(d.getMonth() + 1,2);
            var dd = pad(d.getDate(),2);
            var yy = d.getFullYear();

            var selectedDate = yy + '-' + mm + '-' + dd; //(US)

            var seasons = $.parseJSON(window.seasons);
            //console.log('seasons',seasons);

            picker.setOptions({minDays:7});

            for( var i in seasons ){
                var season = seasons[i];
                //console.log('type: ' + season.type + ' date: ' + selectedDate);
                if( season.type=='high' && season.start <= selectedDate && season.end >= selectedDate ){
                    //console.log('compare ' + season.start + ' < ' + selectedDate + ' > ' + season.end);
                    picker.setOptions({minDays:14});
                    break;
                }
            }

            picker.gotoDate(firstDate);
        },50);

    });

};

function pad(num, size){
    return ('000000000' + num).substr(-size);
}


function onSelectDate(date1,date2){

    const options = {
        localeMatcher: 'best fit',
        numeric: 'auto',
        style: 'short',
        day: 'numeric',
        weekday: 'long',
        month: 'long',
        year: 'numeric',
    };

    const date1_nice = new Intl.DateTimeFormat("nl", options).format(date1);
    const date2_nice = new Intl.DateTimeFormat("nl", options).format(date2);

    options.weekday = 'short';
    options.month = 'short';

    const date1_short = new Intl.DateTimeFormat("nl", options).format(date1);
    const date2_short = new Intl.DateTimeFormat("nl", options).format(date2);

    const diffDays = daysBetween(date1,date2);

    $(".booking-date1").html(date1_nice);
    $(".booking-date2").html(date2_nice);
    $(".booking-days").html(diffDays + ' dagen');

    $(".booking-date1_short").html(date1_short);
    $(".booking-date2_short").html(date2_short);

    $("input[name='StartDate']").val(formatDate(date1));
    $("input[name='EndDate']").val(formatDate(date2));

    $("#DatePreview").show();

    const rentableID = $("input[name='RentableID']").val();

    if( rentableID!=undefined ){
        // in detail view
        updateBookingPrice();
    } else {
        // in overview
        updateFilteredItems();
    }

}


//function updateWeekends(){
//
//    return;
//    //console.log('updateWeekends');
//    $('.day-item').each(function(i){
//        var time = $(this).data('time');
//        var d = new Date(time);
//        var day = d.getDay();
//        if( (day === 6) || (day === 0) ){
//            $(this).addClass('is-weekend');
//        }
//    })
//}

function updateFilteredItems(){
    const rentableID = $("input[name='RentableID']").val();

    if( rentableID==undefined ){
        $('.booking-price').parent().hide();
    }

    $('.caravan-rentals__items').html('laden...');
    var action = $('#Form_FilterForm').attr('action');
    $.post(action, $('#Form_FilterForm').serialize()).done(function(result) {
        $('.caravan-rentals__items').html(result);
    });
}


function updateBookingPrice(){
    const start = $("input[name='StartDate']").val();
    const end = $("input[name='EndDate']").val();
    const rentableID = $("input[name='RentableID']").val();

    if( rentableID==undefined ){
        $('.booking-price').parent().hide();
        return;
    }

    var options = [];
    $.each($("#ReservationForm_ReservationForm_Options input.checkbox:checked"), function(){
        options.push($(this).val());
    });

    var pathElements = window.location.pathname.split('/');
    for (var i = 0; i <= pathElements.length; i++) {
        if (pathElements[i] === 'details') {
            pathElements.length = i;
            break;
        }
    }

    $.ajax({
        url: pathElements.join('/') + '/getprice/'+rentableID+'?StartDate='+start+'&EndDate='+end+'&options='+options.join(',')
    }).done(function(result) {
        $('.booking-price').html(result);
    });
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function daysBetween(StartDate, EndDate) {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;

    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate());
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());

    // so it's safe to divide by 24 hours
    return 1+(start - end) / oneDay;
}
