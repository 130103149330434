import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

export const initMasonry = function() {
    var selector = '.main-menu-fold-out__grid';

    if( $(selector).length==0 ){
        // no main menu fold out
        return;
    }

    var masonries = [];
    $(selector).each(
        function(i,e){
            var masonry = new Masonry(e,{
                itemSelector: '.cell',
                isAnimated: true
            });
            masonries.push(masonry);
            masonry.layout();
        }
    );

    imagesLoaded(document.querySelector(selector), () => {
        for(var i in masonries){
            masonries[i].layout();
        }
    });

};