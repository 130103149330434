export const initSideCart = () => {
    const form1 = $('#VariationForm_Form');
    $(document).on('submit', '#VariationForm_Form', {form: form1}, changeSideCart);
    const form2 = $('#AddProductForm_Form');
    $(document).on('submit', '#AddProductForm_Form', {form: form2}, changeSideCart);
    $(document).on('click', hideSideCart);

    $(document).on('click', '.product-card__tool--add-to-cart', addToCart);
}

function addToCart(event) {
    event.preventDefault();

    let card = $(event.currentTarget).closest('.product-card');
    let price = card.find('.price__current').text().replace('€','').replace(',','.');

    // trigger enhanced e-commerce event
    let cartItem = {
        'name': card.find('.product-card__subtitle').text(),
        'id': card.data('vart') + '',
        'price': price,
        'brand': card.find('.product-card__title').text(),
        'category': $('.product-category__header h1').text(),
        'quantity': 1
    };

    document.dispatchEvent(new CustomEvent("onAddToCart", {detail: {item: cartItem}}));


    $.ajax({
        url: $(this).attr('href'),
        success: function (response) {
            $('[name="action_addtocart"]').addClass('button--is-added');
            $('.side-cart').replaceWith(response);
            $('.side-cart').addClass('side-cart--visible');
            setTimeout(() => {
                $('[name="action_addtocart"]').removeClass('button--is-added');
            }, 1500);
            if ($('#CartForm_CartForm').length > 0) {
                // reload needed
                location.reload();
            }
        }
    });

}

function hideSideCart(event) {
    if (!$(event.target).closest('.side-cart').length) {
        $('.side-cart').removeClass('side-cart--visible');
    }
}

function changeSideCart(event) {
    event.preventDefault();

    $.ajax({
        url: event.data.form.attr('action'),
        type: event.data.form.attr('method'),
        data: event.data.form.serialize(),
        success: function (response) {
            $('[name="action_addtocart"]').addClass('button--is-added');
            $('.side-cart').replaceWith(response);
            $('.side-cart').addClass('side-cart--visible');
            setTimeout(() => {
                $('[name="action_addtocart"]').removeClass('button--is-added');
            }, 1500);
        },
        error: function (response) {
            // console.log('error');
        }
    });

    // trigger enhanced e-commerce event
    let cartItem = window.productData;
    let label = event.data.form.find('select').closest('.field').find('label').text();
    let variation = event.data.form.find('select :selected').text().trim();
    if( variation !== '') {
        if (label !== undefined) {
            variation = label + ': ' + variation;
        }
        cartItem.variation = variation;
    }
    cartItem.quantity = event.data.form.find('input[name=Quantity]').val();
    document.dispatchEvent(new CustomEvent("onAddToCart", {detail: {item: cartItem}}));
}