const lineHeight = 26;

export const initExpandableContent = () => {
  $('.expandable-content__text').each(function (i, el) {
    if ($(el).height() > (lineHeight * 3)) {
      $(el).addClass('expandable-content__text--hidden');
    }
  });

  $(document).on('click', '.expandable-content__button', function () {
    $(this).parent().find('.expandable-content__text').removeClass('expandable-content__text--hidden');
    $(this).remove();
  })
};