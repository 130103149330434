import 'jquery-validation';

const forms = {
    'MemberLoginForm_LoginForm_action_dologin': 'MemberLoginForm_LoginForm',
    'UserForm_Form_action_process': 'UserForm_Form',
    'CheckoutForm_CreateAccountForm_action_docreateaccount': 'CheckoutForm_CreateAccountForm',
    'CheckoutForm_AddressForm_action_setaddress': 'CheckoutForm_AddressForm',
    'BaseForm_OrderForm_action_Order': 'BaseForm_OrderForm',
    'VariationForm_Form_action_addtocart': 'VariationForm_Form',
    'ReservationForm_ReservationForm_action_placeReservation': 'ReservationForm_ReservationForm',
    'SummaryForm_SummaryForm_action_placeReservation': 'SummaryForm_SummaryForm',
    'RequestForm_RequestForm_action_submitRequest': 'RequestForm_RequestForm',
};

/**
 * Pretend to do ajax sending
 *
 * @param formID
 */
const pretendToSend = function (event) {
    let form = $(event.target).closest('form');
    let input = form.find('input[type="submit"]');
    event.preventDefault();

    if (form.valid() && !form.hasClass('ajax')) {
        input.addClass('loading');
        form.addClass('loading');
        form.submit();
    } else if (!form.valid()) {
        input.addClass('error');
    } else {
        input.removeClass('error');
    }

    return false;
};

/**
 * Bootstraps the forms
 */
export const initForms = () => {
    $.each(forms, (button, form) => {
        let buttonID = '#' + button;
        $(document).on('click', buttonID, pretendToSend);
    });

    // init all userforms
    $('.userform').each(
        function (i, e) {
            let button = $(this).find('input[type=submit]');
            button.on('click', function (e) {
                pretendToSend(e);
            })
        }
    );


};