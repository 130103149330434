/**
 * Toggle the active class on the hamburger
 * Set navigation and navigationActive strings to your used classes
 */
export const initNavigation = function () {
    let hamburger = $('.c-hamburger');
    let navigation = $('.your-nav-class');
    let navigationActive = 'your-nav-class--active';
    window.navTimeout = 0;

    hamburger.on('click', () => {
        hamburger.toggleClass('is-active');
        navigation.toggleClass(navigationActive);
    });

    $('.layout-mask').hover(function(e){
        // console.log('layout mask hover');
        clearTimeout(window.navTimeout);
        $('.main-menu-fold-out--active').removeClass('main-menu-fold-out--active');
        $('.layout-mask').removeClass('layout-mask--active');
    });

    $('.main-menu-fold-out,.fold-out-menu__item').hover(function(e){
        // console.log('hover fold out');
        clearTimeout(window.navTimeout);
    });

    $('.main-menu__item--has-fold-out').hover(
        function (e) {
            // console.log('hover in item');
            let target = e.target;
            if (target.tagName === 'A') {
                target = $(target).parent();
            }
            clearTimeout(window.navTimeout);
            window.navTimeout = setTimeout(function () {
                $('.main-menu-fold-out--active').removeClass('main-menu-fold-out--active');
                $('.layout-mask').addClass('layout-mask--active');
                $(target).find('.main-menu-fold-out,.main-menu-fold-out-compact').addClass('main-menu-fold-out--active');
            }, 300);
        },
        function (e) {
            // console.log('hover out item');
            let target = e.target;
            if (target.tagName === 'A') {
                target = $(target).parent();
            }
            clearTimeout(window.navTimeout);
            window.navTimeout = setTimeout(function () {
                $('.layout-mask').removeClass('layout-mask--active');
                $('.main-menu-fold-out--active').removeClass('main-menu-fold-out--active');
            }, 500);
        }
    );

    var mainMenuID = $(".site-header__main-menu").data("main-menu-current");
    if (mainMenuID !== undefined) {
        $('.main-menu__item[data-menu-id=' + mainMenuID + ']').addClass('main-menu__item--current');
    }

};