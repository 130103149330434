export const initProductDetail = function() {
    // console.log('initProductDetail');
    if( window.variationPrices==undefined ){
        return;
    }
    updateVariationPrice();
    $('[name="ProductAttributes[1]"]').on('change',updateVariationPrice);
    $('[name="ProductAttributes[2]"]').on('change',updateVariationPrice);

};

function updateVariationPrice(){

    // console.log('change variation');

    // find all selected variations
    var id_arr = [];

    var val1 = $('[name="ProductAttributes[1]"]').val();
    if( val1 ){
        id_arr.push(val1);
    }
    var val2 = $('[name="ProductAttributes[2]"]').val();
    if( val2 ){
        id_arr.push(val2);
    }

    var id = id_arr.join('_');

    var prices = JSON.parse(window.variationPrices);



    if( prices[id] != undefined ) {
        var price = parseFloat(prices[id]);
        // console.log('price:' + prices[id] + ' => ' + price );

        var formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
        });

        var totalPrice = formatter.format(price);

        //$('.addproductform__price-comment').html('Prijs:');
        $('.product-sidebar__price .price__current').html(totalPrice);
    } else {
        //$('.addproductform__price-comment').html('Maak eerst een keuze');
         // $('.product-sidebar__price').html('');
    }

    var baseprices = JSON.parse(window.variationBasePrices);

    if( baseprices[id] != undefined ) {
        var price = parseFloat(baseprices[id]);
        // console.log('base price:' + baseprices[id] + ' => ' + price );

        var formatter = new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
        });

        var totalPrice = formatter.format(price);

        //$('.addproductform__price-comment').html('Prijs:');
        $('.product-sidebar__price .price__original').html(totalPrice);
    } else {
        //$('.addproductform__price-comment').html('Maak eerst een keuze');
        // $('.product-sidebar__price').html('');
    }



}

