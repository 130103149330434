import Swiper from 'swiper/dist/js/swiper.js';

export const initProductSwiper = function () {
  const container = document.querySelectorAll('.product-swiper');
  if (!container.length) {
    return;
  }

  const ProductSwiper = new Swiper('.product-swiper', {
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.product-swiper__button--next',
      prevEl: '.product-swiper__button--prev',
    },
    slidesPerGroup: 2,
    breakpointsInverse: true,
    breakpoints: {
      640: {
        slidesPerGroup: 3
      },
      1024: {
        slidesPerGroup: 4
      }
    }
  });
};

export const initVivaBanners = function () {
  const vivaBannerSlider = new Swiper('.banner-block__slider', {
    breakpointsInverse: true,
    breakpoints: {
      640: {}
    },
    autoplay: {
      delay: 4500
    },
    loop: true,
    effect: 'slide',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.banner-block__pagination',
      bulletClass: 'banner-block__pagination-bullet',
      bulletActiveClass: 'banner-block__pagination-bullet--active',
      clickable: true
    }
  });
};

export const initProductGallerySwiper = function () {
  const container = document.querySelectorAll('.product-gallery__container');
  const thumbs = document.querySelectorAll('.product-gallery-thumbnails__container');
  if (!container.length && !thumbs.length) {
    return;
  }

  const productGalleryMain = new Swiper('.product-gallery__container', {
    speed: 400,
    spaceBetween: 16,
    pagination: {
      el: '.product-gallery__pagination',
      type: 'bullets',
      bulletClass: 'product-gallery__pagination-bullet',
      bulletActiveClass: 'product-gallery__pagination-bullet--active',
      currentClass: 'product-gallery__pagination-bullet--current',
      clickable: true
    },
    navigation: {
      nextEl: '.product-gallery__button--next',
      prevEl: '.product-gallery__button--prev',
    },
    breakpointsInverse: true,
    breakpoints: {
      640: {
        spaceBetween: 30
      }
    }
  });

  const productGalleryThumbs = new Swiper('.product-gallery-thumbnails__container', {
    speed: 400,
    spaceBetween: 16,
    slidesPerView: 'auto',
    allowTouchMove: true,
    breakpointsInverse: true,
    breakpoints: {
      640: {
        direction: 'vertical',
        spaceBetween: 30,
        allowTouchMove: false,
      }
    },
    on: {
      click: (event) => {
        productGalleryMain.slideTo(productGalleryThumbs.clickedIndex);
      }
    }
  });

  productGalleryThumbs.controller.control = productGalleryMain;
  productGalleryMain.controller.control = productGalleryThumbs;
};
