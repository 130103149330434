// todo abstraheren
export const initProductVideos = function () {

    if( $('.video-block').length ){
        $('.video-block').each(function () {
        let videoBlock = this;
        $('.product-video-button', videoBlock).on('click', function () {

            const url = '/' + $(this).attr('data-embed');
            console.log('click', url);
            //const path = window.location.pathname;
            //const url = `/${embedID}`;
            $.ajax({
                url,
                success: function (response) {
                    $('.product-videos__video', videoBlock).html(response)
                }
            })
        });
        });
    } else {

        let videoBlock = this;
        $('.product-video-button', videoBlock).on('click', function () {

            const url = '/' + $(this).attr('data-embed');
            console.log('click', url);
            //const path = window.location.pathname;
            //const url = `/${embedID}`;
            $.ajax({
                url,
                success: function (response) {
                    $('.product-videos__video', videoBlock).html(response)
                }
            })
        });

    }




};