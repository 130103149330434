export const initCheckout = function() {
  initToggle();
  //initAddressLookup();
  $('input[required="required"]').addClass('required');
  $.validator.messages.required = 'Dit veld is verplicht';
  $('#CheckoutForm_ShippingAddressForm,#CheckoutForm_BillingAddressForm,#CheckoutForm_CreateAccountForm').validate();

  const pickupTime = $('#PickupTime_Holder');
  const pickupDay = $('#PickupDay_Holder');
  const deliveryDate = $('#DeliveryDate_Holder');
  const pickupPointMethod = document.getElementById('Form_ShippingMethodForm_ShippingMethodID_3');
  $('[name="ShippingMethodID"]').change(function () {
    if (pickupPointMethod && pickupPointMethod.checked) {
      pickupTime.hide();
      pickupDay.hide();
      deliveryDate.show();
    } else {
      pickupTime.show();
      pickupDay.show();
      deliveryDate.hide();
    }
  });
  if (pickupPointMethod && !pickupPointMethod.checked) {
    pickupTime.hide();
    pickupDay.hide();
    deliveryDate.hide();
  } else if (pickupPointMethod && pickupPointMethod.checked) {
    deliveryDate.show();
  }
};

function initAddressLookup() {
  $('#CheckoutForm_AddressForm_SilverShop-Checkout-Component-AddressBookShipping_PostalCode, #CheckoutForm_AddressForm_SilverShop-Checkout-Component-AddressBookShipping_AddressNumber')
    .focusout(function () {
      // console.log('find address');
      // todo hookup an address lookup
      // try to find address in existing address table
      // or check against the google api
    })
}

function initToggle() {
  $('[data-toggle-fields]:checked').each(function (i, toggle) {
    toggleFields(toggle);
  });

  $(document).on('click', '[data-toggle-fields]', function (e) {
    toggleFields(this);
  });
}

function toggleFields(toggle) {
  //const toggleFields = $(field).attr('data-toggle-fields');
  const foundField = findFields(toggle);
  if (toggle.checked) {
    foundField.addClass('checkout-step__hidden-field--active');
  } else {
    foundField.removeClass('checkout-step__hidden-field--active');
  }
}

function findFields(toggle) {
  const toggleFields = $(toggle).attr('data-toggle-fields');
  const found = $(`[data-toggle-field='${toggleFields}']`);
  let fields = [];
  found.each(function (i, e) {
    let foundField = $(e).closest('.field.checkout-step__hidden-field');
    if (foundField.length) {
      fields.push(foundField[0]);
    } else {
      fields.push(e);
    }
  });

  return $(fields);
}
