import {initLazyLoad} from "./lazyLoad";

export const initSearch = () => {
    let field = document.getElementById('search');
    if( !field ) return;
    let resultsWindow = document.getElementById('search-results-main');
    let resultsHolder = document.getElementById('search-results-inner');
    let closeButton = document.getElementById('search-results-close-button')
    let debounce = false;
    field.addEventListener('input', function (e) {
        let val = this.value;
        if (debounce) clearTimeout(debounce);
        if (!val) {
            resultsWindow.classList.remove('search-results--active');
            return false;
        }
        debounce = setTimeout(function () {
            fetchSearchResults(val).then(results => {
                if (!field.value) return;
                resultsHolder.innerHTML = results;
                resultsWindow.classList.add('search-results--active');
                let tabs = $('#search-result-tabs');
                new Foundation.Tabs(tabs);
                enableAjaxPagination(resultsHolder);
                window.scrollTo(0, 0);
                initLazyLoad();
            });
        }, 300);
    });
    closeButton.addEventListener('click', function (e) {
        e.preventDefault();
        field.value = '';
        resultsWindow.classList.remove('search-results--active');
    })
}

function enableAjaxPagination(resultsHolder) {
    document.querySelectorAll('.pagination__item').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let link = item.querySelector('a').href;
            console.log(link);
            fetchSearchResults(false, link).then(results => {
                resultsHolder.innerHTML = results;
                let tabs = $('#search-result-tabs');
                new Foundation.Tabs(tabs);
                enableAjaxPagination(resultsHolder);
                window.scrollTo(0, 0);
                initLazyLoad();
            });
        });
    });
}

async function fetchSearchResults(value, link) {
    if (value) {
        let baseURL = window.location.origin + '/home/search';
        link = baseURL + '?ajax=1&search=' + value;
    }
    const response = await fetch(link);
    return await response.text();
}