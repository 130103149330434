export const initCart = () => {
  const form = $('#CartForm_CartForm');
  $(document).on('change', '#CartForm_CartForm :input', {form}, changeCart);
};

function changeCart(event) {
  event.preventDefault();
  const url = event.data.form.attr('action');
  const type = event.data.form.attr('method');
  const data = event.data.form.serialize();
  event.data.form.find(':input').prop('disabled', true);
  $.ajax({ url, type, data, success: function( response ) {
      $('#CartForm_CartForm fieldset').replaceWith($(response).find('#CartForm_CartForm fieldset'));
  }});
}