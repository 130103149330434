import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

export const initGallery = function () {
    $('[data-gallery-index]').on('click', function () {
        let gallery = $(this).closest('[data-gallery]');
        let index = parseInt(eval($(this).attr('data-gallery-index'))) - 1;
        if (gallery.length) {
            let items = JSON.parse($(gallery[0]).attr('data-gallery'));
            let photoSwipe = new PhotoSwipe($('.pswp')[0], PhotoSwipeUI_Default, items, {index: index});
            photoSwipe.init();
        }
    });
};