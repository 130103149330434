import lozad from 'lozad';

export const initFilterForm = () => {
  const form = $('#FiltersForm_FilterForm, #BaseForm_FiltersForm');
  //const form = $('#FiltersForm_FilterForm');

  window.sliderChangeTimeout = 0;

  // hide the buttons when js is enabled
  form.find('.btn-toolbar').hide();

  // submit
  form.on('change', 'input', {form}, submitForm);
  form.on('change', 'select', {form}, submitForm);
  setTimeout(function(){
    form.on('changed.zf.slider', {form}, onSliderChange);
  },2000);

  $(window).on('popstate', {form}, submitForm);

  // expand set
  form.on('click', '.filter-checkbox-set__button--expand', {form}, expandSet);

  checkSelectedCheckboxSets();
};


function checkSelectedCheckboxSets(){
  // always open if selected
  $('.filter-checkbox-set').each(function(){
    if( $(this).find('input:checked').length ){
      $(this).find('.filter-checkbox-set__button--expand').trigger('click');
    }
  })
}

function expandSet(event) {
  const targetListID = $(event.target).attr('data-open');
  const openText = $(event.target).attr('data-open-text');
  const closedText = $(event.target).attr('data-closed-text');
  $(event.target).html().trim() === closedText
    ? $(event.target).html(openText)
    : $(event.target).html(closedText);

  $('#' + targetListID).find('.filter-checkbox-set__option--hidden').toggle();
}


function onSliderChange(e){
  clearTimeout(window.sliderChangeTimeout);
  window.sliderChangeTimeout = setTimeout(function(){
    submitForm(e);
     //console.log('submit timeout');
   },500);
}

function submitForm(event) {
  const form = event.data.form;

  clearTimeout(window.sliderChangeTimeout);

  // check range sliders
  $('.filter-range-field',form).each(
      function(i,e){
        const slider = $(e).find('.slider');
        const min = parseFloat(slider.data('start')).toFixed(2);
        const max = parseFloat(slider.data('end')).toFixed(2);
        const minVal = parseFloat($(e).find('input.filter-range-field__min').val()).toFixed(2);
        const maxVal = parseFloat($(e).find('input.filter-range-field__max').val()).toFixed(2);
        if( minVal===min && maxVal===max ){
          $(e).find('input.filter-range-field__min').attr("disabled", "disabled");
          $(e).find('input.filter-range-field__max').attr("disabled", "disabled");
        }
      }
  );

  const url = form.attr('action');
  const data = event.type === 'popstate'
    ? document.location.search.replace('?','')
    : form.serialize();
  const filter = data ? data + '&action_filter' : data;

  form.off('changed.zf.slider');

  // Disable the form inputs while loading results
  form.find(':input').prop('disabled', true);
  $.ajax({
    url,
    type: form.attr('method'),
    data: filter,
    success: function( response ) {
      if (event.type !== 'popstate') {
        window.history.pushState('', '', url + '?' + filter);
      }

      // todo update set filters after popstate change
      $('.product-category__main').replaceWith($(response).find('.product-category__main'));
      form.find('fieldset').replaceWith($(response).find('#'+ form.attr('id') +' fieldset'));
      form.find('fieldset').foundation();
      setTimeout(function(){
        form.on('changed.zf.slider', {form}, onSliderChange);
      },1000);

      const observer = lozad('.lazy');
      observer.observe();

      checkSelectedCheckboxSets();

      const mobileFilters = $('#off-canvas-filters');
      if (mobileFilters.length) {
        mobileFilters.foundation('close');
      }
    }
  });
}
