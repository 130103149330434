export function initPostCodeCheck() {
  const postalCodeField = $("input[name*='_PostalCode']");
  const houseNumberField = $("input[name*='_HouseNumber']");
  const addressFieldsToggle = $('input.overwrite-address-fields-toggle');

  postalCodeField.on('change', function () {
    if( addressFieldsToggle.is(":checked")){
      return;
    }

    var postCode = $(this).val();
    postCode = postCode.replace(/\s+/g, '');

    $(this).val(postCode);

    if( !postCode ){
      $('.address-preview').html('');
      return;
    }

    if (postCode && houseNumberField.val()) {
      getAddress(postCode, houseNumberField.val())
    }
  });

  houseNumberField.on('change', function () {
    if( addressFieldsToggle.is(":checked")){
      return;
    }

    var houseNumber = $(this).val();
    houseNumber = houseNumber.replace(/\s+/g, '-');
    $(this).val(houseNumber);

    if( !houseNumber ){
      $('.address-preview').html('');
      return;
    }

    var postCode = postalCodeField.val();

    if (houseNumber && postCode) {
      getAddress(postCode, houseNumber)
    }
  });

  addressFieldsToggle.on('change', function () {
    if ($(this).is(":checked")) {
      $('.overwrite-address-fields').removeClass('overwrite-address-fields--hidden');
      $('.address-preview').hide();
    } else {
      $('.overwrite-address-fields').addClass('overwrite-address-fields--hidden');
      $('.address-preview').show();
    }
  });
}

function getAddress(postalCode, houseNumber) {

  // strip whitespaces
  postalCode = postalCode.replace(/\s+/g, '');
  // $('input[name*="_PostalCode"]').val(postalCode);

  $('.address-preview').html('<label>We hebben dit adres gevonden:</label><p>Postcode zoeken...</p>');
  $.ajax({
    url: `/checkpostcode/${postalCode}/${houseNumber}`
  }).done(function (res) {
    const address = JSON.parse(res);
    $('select[name*="_Country"]').val(address.countryCode);
    $('input[name*="_City"]').val(address.locality);
    $('input[name*="_StreetAddress"]').val(address.streetName);
    $('input[name*="_HouseNumber"]').val(address.streetNumber);
    // $('input[name*="_PostalCode"]').val(address.postalCode);
    $('input[name*="_Latitude"]').val(address.latitude);
    $('input[name*="_Longitude"]').val(address.longitude);

    var houseNumber = $('input[name*="_HouseNumber"]').val();
    houseNumber = houseNumber.replace(/\s+/g, '-');
    if( !houseNumber ){
      $('input[name*="_HouseNumber"]').focus().valid();
      $('.address-preview').addClass('error').removeClass('success');
    } else {
      $('.address-preview').removeClass('error').addClass('success');
    }

    $('.address-preview').html(`
      <label>We hebben dit adres gevonden:</label>
      <p class="callout">
        ${address.streetName} ${houseNumber}<br/>
        ${address.postalCode} ${address.locality}
      </p>
    `);

    $('.overwrite-address-fields-toggle').removeClass('overwrite-address-fields-toggle--hidden');
  });
}